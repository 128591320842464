/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

@import '~@angular/material/theming';


$config: mat-typography-config(
  $font-family: 'Open Sans, sans-serif'
);

@include mat-core($config);

html,body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif; 
  min-height: 90vh;
}

.mt-5 {
 margin-top: 40px;
}

.logo-wrapper {width: 125px;height: 25px;display: block;}
.logo-wrapper a{ cursor: default;}
.logo-wrapper img{width: 100%;height: 100%;cursor: pointer;}
.logo-section{
  line-height: 1;
}
.login-wrapper {
  height: 100%;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg,#06a8ff 0,#4f507a 100%);
}

.login-wrapper .auth-form,.mobile-form{
  min-width: 82vw;
  max-width: 350px;
  width: 82vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-wrapper .auth-full-width, .auth-full-width,
.login-wrapper .btn-block, .btn-block {
  width: 100%;
}

.login-wrapper mat-card-header {
  text-align: center;
  width: 100%;
  display: block;
  font-weight: 700;
}

.login-wrapper mat-card-header .mat-card-header-text{
  margin: 0;
}

.login-wrapper mat-card-header mat-card-title {
  font-size: 30px !important;
  margin: 0;
}

.login-wrapper .mat-card {
  padding: 10px 20px 60px;
  height: 95%;
  box-sizing: border-box;
}

.login-wrapper .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.8375em 0;
}

.change-password .mat-form-field-suffix {
  position: absolute !important;
  right: 1px;
  bottom: 16px;
}

.login-wrapper .mat-form-field.mat-primary{
  margin-top: 10px;
}

.mat-form-field {
  line-height: 1.4;
}

.login-wrapper p {
  color: #6C6C6C;
  font-size: 16px;
  line-height: 20px;
  margin: 6px 0 0;
}

.main-container {
  padding: 80px 20px 20px;
  padding-bottom: 20px;
}

.progress-spinner {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background:  rgba(229, 224, 223, 0.315);
}

.mobile-sec-area {
    min-height: 84vh;
    max-height: 100vh;
    overflow-y: auto;
}

.signin-btn,.flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

// 
.justify-between {
    display: flex;
    justify-content: space-between;
}
.align-center{
    display: flex;
    align-items: center;
}
.justify-center{
  display: flex;
  justify-content: center;
}
//
.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
}
.filter-menu.mat-menu-panel{
  max-width: 100%;
  z-index: 5;
}

//google place API css
.pac-item{
  padding: 7px 4px 4px 4px;
  overflow: visible;
  white-space: pre-wrap;
  line-height: 1.4;
}
.pac-logo .pac-item span.pac-icon{
  display: none;
}
.pac-logo .pac-item span.pac-item-query{
  display: block;
  line-height: 1;
}
.pac-logo:after{
  display: none;
}

.pac-container{
  z-index: 1001 !important;
}
//--------------------------------------------
.add-lead .mat-chip-input {
  width: 45px;
  flex: 1 0 45px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.add-lead .mat-chip-with-trailing-icon{
  max-width: 100%;
  margin: 4px 0 !important;
}
.add-lead .buisness-cnt-area{
  width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

th.mat-header-cell .mat-sort-header-container {
  justify-content: center;
}

th.mat-header-cell:first-child .mat-sort-header-container {
  justify-content: flex-start;
}

.profile-menu {
  background-color: #06a8ff;
}

.text-overflow{
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-overflow-name{
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.mat-checkbox-layout .mat-checkbox-label {
  max-width: 132px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mat-tooltip {
  background-color: #555;
  color: #fff;
  height: auto;
  font-weight: 400;
  border-radius: 5px;
  font-size: 13px;
  padding: 8px;
  text-align: center;
  box-shadow: 0 1px 3px 0 rgb(28 28 28 / 35%);
}
// .mat-tooltip::after {
//   width: 0;
//   height: 0;
//   content: '';
//   position: absolute;
//   border-left: 0.5rem solid transparent;
//   border-right: 0.5rem solid transparent;
//   border-bottom: 0.5rem solid rgb(28 28 28 / 65%);
// }
.mat-tooltip.below {
  overflow: initial;
  margin-top: 0.3rem;
  word-break: break-word;
}
// .mat-tooltip.below:after {
//   top: -0.5rem;
//   left: 46%;
//   transform: rotate(0);
// }

.chip-title{
  padding: 20px 10px;
  font-size: 14px;
}

.basic-chip{
  padding: 0px 18px;
  border-radius: 50px;
  margin: 5px;
  font-size: 14px;
}

.background-white{
  background: white;
  color: black;
}

.scroller {
  overflow-y: scroll;
  scrollbar-color: rgb(0 0 0 / 12%) #e0e0e0;
  scrollbar-width: thin;
}

.table-container::-webkit-scrollbar {
  height: 4px;
}

/* Track */
.table-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e0e0e0; 
  border-radius: 5px;
}

/* Handle */
.table-container::-webkit-scrollbar-thumb {
  background:rgb(0 0 0 / 12%); 
  border-radius: 5px;
}

.table-container:hover::-webkit-scrollbar-thumb {
  background:rgba(0, 0, 0, 0.363); 
}

@media only screen and (max-width: 405px) {
  .mat-paginator-range-label {
    margin: 0 18px 0 24px !important;
  }
}

.followup-time .mat-form-field-suffix .mat-icon {
  font-size: 110%;
}
.cancel-button{
  background: #ffffff;
  color: #00abff;
  border-color: #00abff!important;
  width: 6rem;
  border: 1px solid #00abff!important;
  font-weight: 700;
}
.cancel-button:hover{
  background: #ffffff;
  color: #00abff;
  border-color: #00abff!important;
  width: 6rem;
  border: 1px solid #00abff!important;
  font-weight: 700;
}